import React from 'react';
import { Link } from 'gatsby';
import { formatTimestampToDateWithDashes } from '../../utils/format';
import { formatDateToFrenchFormat } from '../../utils/format';

const AdviceCard = ({ currentAdvice }) => {

  return (
    <div className="relative bg-gray-50 py-12 px-4 mx-2 lg:mx-8 lg:px-8">
      <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:max-w-none">
        <div className="flex flex-col rounded-lg h-96 shadow-lg shadow-gray-700 border-0 overflow-hidden">
          <div className='h-64'>
            <img src={currentAdvice.image} className='object-cover h-64 w-full' alt={currentAdvice.desc}></img>
          </div>
          <div className="flex-1 bg-white p-2 flex flex-col justify-between">
            <div className="flex-1 block mt-2">
              <p className="text-semi py-3 text-center text-grey-lightDark">
                {currentAdvice.title}
              </p>
              <p className="mt-3 line-clamp-8 text-xs text-grey-light p-4 h-44 break-words">
                {currentAdvice.description}
              </p>
            </div>
            <div className="py-2">
              <div className="ml-3">
                <div className='flex items-center underline border-b border-gray-200'>
                  <p className="text-s font-medium py-4">
                    <Link to={`/${currentAdvice.lang}/advice/${currentAdvice.slug}`} className=" text-s px-2 py-5 text-gray-700 font-bold">
                      Lire la suite
                    </Link>
                  </p>
                  <svg className="h-5 w-5 text-black" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <polyline points="9 6 15 12 9 18" />
                  </svg>
                </div>
                <div className="flex items-center justify-around space-x-1 h-20 py-5 pb-4 text-xs text-grey-lightDark">
                  <span className="dateTime">
                    {formatDateToFrenchFormat(formatTimestampToDateWithDashes(currentAdvice.date))}
                  </span>
                  <span aria-hidden="true"> &middot; </span>
                  <span> {currentAdvice.author} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdviceCard
