import React from 'react';
import i18n from "../../../i18n";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import CoverImage from '../../components/CoverImage';
import blogImage from '../../../assets/blogImage.png';
import AdviceCard from '../../components/AdviceCard';
import NavBarMenu from '../../components/NavBarMenu';
import generateMeta from '../../utils/meta';

const AdvicePage = ({ data, pageContext, t }) => {
  const sortedFirstLevelCategorySlugs = ["femme", "enfant", "homme", "coaching-capillaire"];
  // i18n used for translation
  i18n(pageContext.locale);

  let AdviceCardContent = data.allContentfulAdviceCard.edges.map(({ node: adviceContent }) => {
    const defaultImage = adviceContent.image.file.url;
    const defaultDesc = adviceContent.image.desc;
    const defaultTitle = adviceContent.title;
    const defaultDescription = adviceContent.description.description;
    const defaultDate = adviceContent.date;
    const defaultLang = pageContext.locale;
    const defaultAuthor = adviceContent.author;
    const urlAdvicePage = adviceContent.slug
    return {
      image: defaultImage,
      desc: defaultDesc,
      title: defaultTitle,
      description: defaultDescription,
      date: defaultDate,
      lang: defaultLang,
      author: defaultAuthor,
      slug: urlAdvicePage
    }
  });

  // SEO
  const initMetas = [{
    name: 'og:url',
    content: `${process.env.GATSBY_WEBSITE_URL}${pageContext.currentPath}`,
  }];

  const metas = generateMeta(initMetas, data?.markdownRemark?.frontmatter);

  return (
    <Layout
      lang={pageContext.locale}
      currentPath={pageContext.currentPath}
      firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
        return (
          sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
        )
      })}
      secondLevelCategories={data.menuSecondLevel.edges}
      thirdLevelCategories={data.menuThirdLevel.edges}
    >
      <Helmet>
        <html lang="fr" />
        <title>Blog Zawema</title>
        {metas.map(meta => (
          <meta key={meta.name} name={meta.name} content={meta.content} />
        ))}
      </Helmet>
      <NavBarMenu
        firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
          return (
            sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
          )
        })}
        secondLevelCategories={data.menuSecondLevel.edges}
        thirdLevelCategories={data.menuThirdLevel.edges}
        lang={pageContext.locale}
      />
      <>
        <CoverImage
          imageDesktop={blogImage}
          imageMobile={blogImage}
          title="Blog"
          altDesktop="blog zawema"
          altMobile="blog zawema"
        />
      </>
      <div className='grid grid-cols-1 lg:grid-cols-3'>
        {
          (AdviceCardContent.length === 0)
          && (
            <p> Aucun article disponible pour le moment </p>
          )
        }
        {
          (AdviceCardContent.length !== 0)
          && (
            AdviceCardContent.map((currentAdvice, key) => {
              return (
                <AdviceCard
                  currentAdvice={currentAdvice}
                  key={key}
                />
              )
            })
          )
        }
      </div>
    </Layout>
  );
};

export default AdvicePage;

export const query = graphql`
    query( $locale: String! ) {
        menuFirstLevel: allContentfulCategorySve(filter: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}) {
            edges {
              node {
                slug
                categoryName
                
              }
            }
          }
        menuSecondLevel: allContentfulCategorySve(filter: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}
        sort : {fields: [slug], order: ASC}) {
            edges {
              node {
                slug
                categoryName
                categoryEncartTitle
                parentCategory {
                  slug
                  categoryName
                  categoryEncartTitle
                }
              }
            }
          }
        menuThirdLevel: allContentfulCategorySve(filter: {parentCategory: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}}
        sort : {fields: [slug], order: ASC}) {
            edges {
              node {
                slug
                categoryName
                categoryEncartTitle
                parentCategory {
                  slug
                  categoryName
                  categoryEncartTitle
                  parentCategory {
                    slug
                    categoryName
                    categoryEncartTitle
                  }
                } 
              }
            }
          }
        allContentfulAdviceCard(filter: { node_locale: {eq: $locale}} sort : {fields: [date], order: DESC}){
            edges {
                node {
                    image {
                        description
                        file {
                            url
                        }
                    }
                    title
                    description {
                      description
                    }
                    slug
                    date
                    author
                }
            }
        }
        markdownRemark(fileAbsolutePath: {regex: "/advice.md/"}) {
          html
          frontmatter {
              title
              description
              ogtitle
              ogdescription
          }
        }
    }
`